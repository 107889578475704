@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import "https://pro.fontawesome.com/releases/v5.15.4/css/all.css";


body {
  margin: 0;
  padding: 0;
}

.navbar-item:hover,
.navbar-link:hover {
  background-color: rgba(255, 255, 255, 0.199) !important;
}

.whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.whatsapp a {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #25d366;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  transition: all 0.3s ease;
}

.whatsapp a:hover {
  background-color: #128c7e;
}


.goToTop {
  position: fixed;
  bottom: 20px;
  right: 90px;
  z-index: 99;
}

.goToTop > div {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #0690f9;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  transition: all 0.3s ease;
}

.goToTop > div:hover {
  background-color: #128c7e;
}

.navbar-dropdown {
  background-color: #ffffff2b;
}

.service-item {
  transition-duration: 1s;
}

.service-item:hover {
  transform: scale(1.05);
  transition: all 1s ease;
}

.width80 {
  width: 80%;
}

.padding6 {
  padding: 3rem !important;
}

.marginb2 {
  margin-bottom: 2rem !important;
}

.has-background-white {
  background-color: #ffffff2b !important;
  border-radius: 7px;
}

.top-section-home {
  width: 120vw;
  padding-left: 0;
  align-items: 'flex-start';
  justify-content: 'flex-start !important';
  display: 'flex'
}

#content-top-home {
  width: 100vw;
  height: 100vh;
}

.title-section-home {
  font-size: 60px;
}

.section-content-img-text-home>figure,
.section-content-img-text-home>h2 {
  width: 50%;
}

.contact-img {
  height: 70vh;
}

.form-contact {
  width: 50%;
}

.redes {
  width: 180px;
}

.lateralMenuChild {
  width: 50vw;
  height: 100vh;
  z-index: 101;
  background-color: #297195c4;
  border-left: rgba(255, 255, 255, 0.567) 5px solid;
}

.lateralMenu {
  width: 110vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: flex-end !important;
  z-index: 100;
  right: 0;
  background-color: rgba(0, 0, 0, 0.376);
}

/*Animations*/
.fade-in-left {
  justify-content: flex-start;
  transition: all 5s ease-out;
  -webkit-animation: fade-in-left-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-in-left-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-left-animation {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-right {
  justify-content: flex-start;
  transition: all 5s ease-out;
  -webkit-animation: fade-in-right-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-in-right-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-right-animation {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-in-top {
  justify-content: flex-start;
  transition: all 5s ease-out;
  -webkit-animation: fade-in-top-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-in-top-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-top-animation {
  0% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-down {
  justify-content: flex-start;
  transition: all 5s ease-out;
  -webkit-animation: fade-in-down-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: fade-in-down-animation 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes fade-in-down-animation {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive */
@media (max-width: 500px) {

  .padding4Mobile {
    padding: 1rem !important;
  }

  .marginbm2 {
    margin-bottom: 0.5rem !important;
  }

  .fontsize20m {
    font-size: 30px !important;
  }
}

@media (max-width: 900px) {
  .section-home {
    height: unset;
  }

  .top-section-home {
    width: 100vw;
    height: 100vh;
  }

  #content-top-home {
    width: 100vw;
    height: 98vh;
    margin-top: 25%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .title-section-home {
    font-size: 2rem;
  }

  .section-content-img-text-home {
    flex-direction: column;
  }

  .section-content-img-text-home>figure,
  .section-content-img-text-home>h2 {
    width: 100%;
  }

  .margin-button-mobile-20 {
    margin-bottom: 20px;
  }

  .contact-form {
    flex-direction: column;
  }

  .contact-img {
    width: 80vw;
    height: unset;
  }

  .form-contact {
    width: 100%;
  }

  .footer .column {
    text-align: center;
  }

  .redes {
    width: 75%;
    margin: auto;
  }

  .longCardImage {
    display: none;
  }

  .padding6 {
    padding: 1rem !important;
  }

}

@media (max-width: 1400px) {
  .widthMobile {
    width: 100% !important;
  }
}